import React, {Fragment} from 'react';

const NotFound = () => {
    return (
        <Fragment>
            <section className="hare-krishna">
                <div className="App ">
                    <h1>Hare Krishna</h1>
                    <h1>Hare Krishna</h1>
                    <h1>krishna Krishna</h1>
                    <h1>Hare Hare</h1>
                    <h1>Hare Rama</h1>
                    <h1>Hare Rama</h1>
                    <h1>Rama Rama</h1>
                    <h1>Hare Hare</h1>
                </div>
            </section>
        </Fragment>
    );
};

export default NotFound;

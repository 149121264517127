import React from "react";

const Select = ({ name, label, options, error, ...rest }) => {
    return (
        <React.Fragment>
            <div className="form-group">
                <label htmlFor={name}>{label}</label>
                <select name={name} id={name} {...rest} className="form-control">
                    <option value="Other"/>
                    {options.map((option) => (
                        <option key={option._id} value={option._id}>
                            {option.name}
                        </option>
                    ))}
                </select>
                {error && <div className="alert alert-danger">{error}</div>}
            </div>
        </React.Fragment>
    );
};

export default Select;